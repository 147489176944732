import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../Breadcrumb';
import { ACCESS_TOKEN, IMAGE_URL } from '../constants';
import Header from '../header';
import { findAdBy, findAdContentValueBy, findAdsBy, getCSRFToken, getCurrentUser, getEspots, getStores, updateAd, updateJsonAd, uploadFile } from '../util/APIUtil';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import Select from 'react-select';
import Moment from 'moment';


const EditAdConfig = () => {

  let { id } = useParams();

  const[firstName, setFirstName] = useState('');
  const[lastName, setLastName] = useState('');

  const[adName, setAdName] = useState('');
  const[selectAdName, setSelectAdName] = useState(-1);
  const[startDate, setStartDate] = useState(new Date())
  const[endDate, setEndDate] = useState(null)
  const[store, setStore] = useState([]);
  const[link, setLink] = useState('');
  const[newTab, setNewTab] = useState(false);
  const[cookies] = useCookies(ACCESS_TOKEN)
  const[error, setError] = useState('');
  const[errorStartDate, setErrorStartDate] = useState('');
  const[errorEndDate, setErrorEndDate] = useState('');
  const[success, setSuccess] = useState('');
  const [csrfTokenState, setCsrfTokenState] = useState(''); 
  const[imageUrl, setImageUrl] = useState('');

  let navigate = useNavigate()

  const[image, setImage] = useState(null);

  Moment.locale('en');

  const [espotNames, setEspotNames] = useState([])
  const [data, setData] = useState([]);

  const handleStoreChange = (e) => {
    setStore(Array.isArray(e) ? e.map(x => x.value) : []);
  }


  useEffect(() =>{

    getStores().then(response =>{

      console.log(response);

      let stores = [];
      let storeIds = [];
      if(response.length){

        response.forEach(element => {

          console.log(element.id);
          console.log(element.name);

          let store = {
            value: element.id,
            label: element.name
          }

          stores.push(store);
          storeIds.push(element.id);

        })
      }

      setData(stores);
      setStore(storeIds);

    }).catch(error =>{


    })

  }, [])

  useEffect(() =>{

    getEspots().then(response =>{

      console.log(response);

      let espotNames = [];
      if(response.length){

        response.forEach(element => {

          console.log(element.id);
          console.log(element.name);

          let espot = {
            value: element.name,
            label: element.name
          }

          espotNames.push(espot);

        })
      }

      setEspotNames(espotNames);

    }).catch(error =>{


    })

  }, [])

  useEffect(() =>{

    setError('');
    setSuccess('');
    let token = cookies.token;
    getCurrentUser(token).then(response =>{

        if(response.status === 'error'){

            navigate("/login")
    
          }
          if(response.status === 'success'){
    
              setFirstName(response.firstName);
              setLastName(response.lastName);
              getCallToForm();
              
          }
    
          console.log(response);

    }).catch(error =>{

      console.log(error);
      navigate("/login")

    })

  }, [])

  const loadAdInfo = () =>{

    if(!id){

      navigate('/adconfig')

    }else{

      let req = {};
      req.id = id;
      findAdBy(Object.assign({}, req), csrfTokenState).then(response =>{

        response.forEach(element => {
          
          setAdName(element.content_name)
          setSelectAdName(element.espotname)
          
          let image = "";
          let link = "";
          let newTab = false;
          let storeIds = [];
          Object.keys(element.json).forEach(key =>{

            let elem = element.json[key];
            
            storeIds.push(Number(key));
            
            if(image === ''){

              image = elem.image;
            }

            if(link === ''){

              link = elem.link.replace(key, "[dealer]");
            }

            newTab = elem.newTab;

          });

          
          setStore(storeIds)
          setImage(image)
          setImageUrl(image);
          setLink(link)
          setNewTab(newTab)
          setStartDate(new Date(element.startdate))
          setEndDate(new Date(element.enddate))
          

        });
        console.log(response)

      }).catch(error =>{

        console.log(error)
      })
    
    }
  }

  const onFileChange = (e) =>{

    setImage(e.target.files[0]);

  }

  const handleAdName = e =>{

    setAdName(e.target.value);

  }

  const handleSelectAdName = e =>{

    setSelectAdName(e.target.value);

  }

  const customSelectStyles = {
  
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#191919",
      color: "white",

    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "white"
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: "rgb(9, 113, 241)",
      color: "white",
      borderRadius:"3px"
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      backgroundColor: "rgb(9, 113, 241)",
      color: "white"
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#191919",
      color: "white",
      zIndex:999999999999999,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      backgroundColor: "#191919",
      color: "white"
    }),option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#191919':"#ffffff",
    }),
  };

  const handleSubmit = () =>{

    setError('');
    setSuccess('');
    if(!adName.trim()){

      window.scrollTo(0, 0);
      setError('Enter Ad Name!');

    }else if(selectAdName === -1){

      window.scrollTo(0, 0);
      setError('Enter Choose Ad Spot!');

    }else if(!store.length){

      window.scrollTo(0, 0);
      setError('Please choose store(s)!');

    }else if(!image){

      window.scrollTo(0, 0);
      setError('Please upload image!');

    }else if(!link){

      window.scrollTo(0, 0);
      setError('Please enter link!');

    }else if(!startDate){

      window.scrollTo(0, 0);
      setError('Please choose Start Date!');

    }else if(!endDate){

      window.scrollTo(0, 0);
      setError('Please choose End Date!');

    }else if(errorStartDate !== '' && errorStartDate !== null){

      window.scrollTo(0, 0);
      console.log("Error Start Date ::: "+errorStartDate);
      setError('Please update Start Date!');

    }else if(errorEndDate !== '' && errorEndDate !== null){
      window.scrollTo(0, 0);
      console.log("Error End Date ::: "+errorEndDate);
      setError('Please update End Date!');
      
    }else{

      console.log(adName);
      console.log(selectAdName);
      console.log(store)
      console.log(image);
      console.log(link);
      console.log(startDate);
      console.log(Moment(new Date(startDate)).format('yyyy-MM-DD HH:mm:ss'))
      console.log(endDate);
      console.log(Moment(new Date(endDate)).format('YYYY-MM-DD hh:mm:ss a'))
      console.log(newTab)
  
      const formData = new FormData();
      formData.append("myFile", image);
    
      console.log(image);


      if(typeof image !== 'string'){

        uploadFile(formData, csrfTokenState).then(response =>{

          console.log(response)
          adUpdation();
  
        }).catch(error =>{
  
          console.log(error)
        })

      }else{

        adUpdation();

      }
      
    }    
  }

  const adUpdation = () =>{



    let finalReq = {};
    let options = {};
    let adsInfo = {};
      store.forEach(element => {
        let req = {}
        req.adName = adName;
        req.espotName = selectAdName;
        req.store = element;
        if(image instanceof File){

          req.image = IMAGE_URL+image.name;
        }else{
          req.image = image;
        }
        
        req.link = link.replace("[dealer]", element);
        req.startDate = Moment(new Date(startDate)).format('yyyy-MM-DD HH:mm')+':00';
        req.endDate = Moment(new Date(endDate)).format('yyyy-MM-DD HH:mm')+':59';
        req.newTab = newTab;
        adsInfo[element] = req;

      });
  
      options.adName = adName;
      options.espotName = selectAdName;
      options.startDate = Moment(new Date(startDate)).format('yyyy-MM-DD HH:mm')+':00';
      options.endDate = Moment(new Date(endDate)).format('yyyy-MM-DD HH:mm')+':59';
      options.json = adsInfo;

      finalReq.options = options;
      finalReq.id = id;

      console.log(finalReq);

      updateJsonAd(Object.assign({}, finalReq), csrfTokenState).then(response => {
  
        if(response){

          if(response.status){

            setSuccess('Ad ('+(adName === ''? selectAdName: adName)+') updated successfully!')
            
          }else{

            setError('Something went wrong. Please try again!')
          }
        }
        console.log(response)
  
      }).catch(error => {
  
        console.log(error)
  
      })
  }

  async function getCallToForm() {
      
    getCSRFToken().then(response =>{

      console.log(response)
      setCsrfTokenState(response.csrfToken)

    }).catch(error =>{
      console.log(error)

    })

  }

  useEffect(() =>{

    if(csrfTokenState){
      loadAdInfo();
    }

  }, [csrfTokenState])

  return (
    
    <div>
        <Header firstName={firstName} lastName={lastName} type='dashboard'/>
        <div className="main-container" id="container">
            <div id="content" className="main-content">
                <Breadcrumb name={"Dashboard"} url={"/"}/>
                <div className="container-fluid login-three-container mt-4">
                    <div className="row main-login-three">
                        <div className="col-xl-8 col-lg-8 col-md-6">
                            <div className="d-flex flex-column justify-content-between right-area widget">
                                <div>
                                <h2 className="text-align-center">Edit Ad</h2>
                                    <div className="login-three-inputs mt-5">
                                    <div className='text-align-center' style={{color:'red', fontWeight:'bold'}}>{error}</div><br/>
                                    <div className='text-align-center' style={{color:'green', fontWeight:'bold'}}>{success}</div><br/>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Ad Name: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <input type="text"  placeholder='Ad Name' className='form-control' value={adName} onChange={handleAdName} readOnly/> 
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Ad Spot: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <select className="form-control" placeholder='Choose Ad Spot' value={selectAdName} onChange={handleSelectAdName}>
                                              <option id='-1' value='-1'>Choose Ad Spot</option>
                                              {espotNames.map((e, key) => {
                                                  return <option id={key} key={key} value={e.value}>{e.value}</option>;
                                              })}
                                            </select>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Store(s): </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                          <Select
                                              styles={customSelectStyles}
                                              placeholder="Choose Store(s)"
                                              value={data.filter(obj => store.includes(obj.value))} // set selected values
                                              options={data} // set list of the data
                                              onChange={handleStoreChange} // assign onChange function
                                              isMulti
                                              isClearable
                                              isDisabled={false}
                                            />
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Image: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <input className='form-control' type='file' onChange={onFileChange} accept="image/*"/>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Current Image: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <img src={imageUrl} style={{width:"100%"}}/>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Link: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                              <input type="text" placeholder='Please enter Link' className='form-control' value={link} onChange={e => setLink(e.target.value)}/>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Open in new tab: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                              <input type="checkbox" placeholder='Open in new tab' className='form-control newtab-checkbox' checked={newTab} onChange={e => setNewTab(e.target.checked)}/>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Start Date: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                              <MobileDateTimePicker
                                                value={startDate}
                                                onChange={(newValue) => {
                                                  setStartDate(newValue);
                                                }}
                                                label="Start Date"
                                                onError={(reason, value)=>{setErrorStartDate(reason)}}
                                                inputFormat="yyyy/MM/dd hh:mm a"
                                                mask="___/__/__ __:__ _M"
                                                renderInput={(params) => <TextField placeholder='___/__/__ __:__ _M' className='form-control' {...params} />}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">End Date: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                              <MobileDateTimePicker
                                                value={endDate}
                                                onChange={(newValue) => {
                                                  setEndDate(newValue);
                                                }}
                                                label="End Date"
                                                onError={(reason, value)=>{setErrorEndDate(reason)}}
                                                minDate={new Date(startDate) > new Date()? new Date(startDate).setDate(new Date(startDate).getDate() + 1) : new Date().setDate(new Date().getDate()+1)}
                                                inputFormat="yyyy/MM/dd hh:mm a"
                                                mask="___/__/__ __:__ _M"
                                                renderInput={(params) => <TextField className='form-control' placeholder='___/__/__ __:__ _M' {...params} />}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-xl-4 col-lg-12 col-md-12 col-sm-12'></div>
                                        <button className="text-white btn bg-gradient-primary" onClick={handleSubmit}>Submit<i className="las la-key ml-2"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditAdConfig
