import React from 'react'
import "./assets/bootstrap/css/bootstrap.min.css"
import "./assets/custom/css/main.css"
import "./assets/custom/css/structure.css"
import "./index.css"
import "./assets/custom/css/loader.css"
import "./assets/custom/css/authentication/auth_3.css"
import "./assets/custom/css/darkmode/global.css"
import "./assets/custom/css/structure.css"
import 'rsuite/dist/rsuite.css'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN } from './constants'
import { useCookies } from 'react-cookie'


const Header = (props) => {

    const [cookies, setCookie, removeCookie] = useCookies(ACCESS_TOKEN)
    const [openSettings, setOpenSettings] = useState(false);

    let navigate = useNavigate(); 
      const login = () =>{ 
        let path = `/login`; 
        navigate(path);
      }

      const handleLogout = () =>{

        removeCookie(ACCESS_TOKEN,{path:'/'});        ;
        login()

      }
  return (
         
    <div className='header-container fixed-top'>
      <header className="header navbar navbar-expand-sm">
            <ul className="navbar-item theme-brand flex-row  text-center">
                <li className="nav-item theme-text">
                    <a href="/" className="nav-link"> GAM </a>
                </li>
            </ul>
            {/* <ul className="navbar-item flex-row ml-md-0 ml-auto">
                <li>
                    {

                            (props.type === 'dashboard')?<a href='/' className="nav-link dropdown-toggle d-flex align-center" style={{"color":"yellow"}}> Dashboard </a>:<a href='/' className="nav-link dropdown-toggle d-flex align-center text-primary"> Dashboard </a>
                    }
                    
                </li>
            </ul> */}
            <ul className="navbar-item flex-row ml-md-0 ml-auto">
                <li>
                    {
                        (props.type === 'dashboard')?<a href='/' className="nav-link dropdown-toggle d-flex align-center" style={{"color":"yellow"}}> Dashboard </a>:<a href='/' className="nav-link dropdown-toggle d-flex align-center text-primary"> Dashboard </a>
                    }
                    
                </li>
            </ul>
            <ul className="navbar-item flex-row ml-md-0 ml-auto">
                <li>
                    {

                            (props.type === 'stories')?<a href='/stories' className="nav-link dropdown-toggle d-flex align-center" style={{"color":"yellow"}}> Vendor Stories </a>:<a href='/stories' className="nav-link dropdown-toggle d-flex align-center text-primary"> Vendor Stories </a>
                    }
                    
                </li>
            </ul>
            <ul className="navbar-item flex-row ml-md-auto">
            <li className="nav-item dropdown header-setting">
                <a href="javascript:void(0);" onClick={() => setOpenSettings(!openSettings)} className="nav-link dropdown-toggle rightbarCollapse" data-placement="bottom">
                    <i className="las la-sliders-h"></i>
                </a>
                    <div className={openSettings?"dropdown-menu position-absolute show":"dropdown-menu position-absolute"} aria-labelledby="userProfileDropdown">
                        <div className="nav-drop is-account-dropdown">
                            <div className="inner">
                                <div className="nav-drop-header">
                                      <span className="text-primary font-15">Welcome to GAM!</span>
                                </div>
                                <div className="nav-drop-body account-items pb-0">
                                    <a id="profile-link" className="account-item" href="pages_profile.html">
                                        <div className="media align-center">
                                            <div className="media-left">
                                                <div className="image">
                                                    
                                                </div>
                                            </div>
                                            <div className="media-content ml-3">
                                                <h6 className="font-13 mb-0 strong">{props.firstName} {props.lastName}</h6>
                                            </div>
                                            <div className="media-right">
                                                <i data-feather="check"></i>
                                            </div>
                                        </div>
                                    </a>
                                    <hr className="account-divider"/>
                                    <a className="account-item" href="javascript:void(0)" onClick={handleLogout}>
                                        <div className="media align-center">
                                            <div className="icon-wrap">
                                                <i className="las la-sign-out-alt font-20"></i>
                                            </div>
                                            <div className="media-content ml-3">
                                                <h6 className="font-13 mb-0 strong ">Logout</h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </header>
    </div>
  )
}

export default Header
