import React, { useRef, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../Breadcrumb';
import { ACCESS_TOKEN, IMAGE_URL } from '../constants';
import Header from '../header';
import { createJsonAd, getCSRFToken, getCurrentUser, getEspots, getStores, uploadFile } from '../util/APIUtil';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import Select from 'react-select';
import Moment from 'moment';


const AdConfig = () => {

  const[firstName, setFirstName] = useState('');
  const[lastName, setLastName] = useState('');

  const[adName, setAdName] = useState('');
  const[selectAdName, setSelectAdName] = useState(-1);
  const[startDate, setStartDate] = useState(Moment(new Date()).format("yyyy-MM-DD")+' 00:00:00')
  const[endDate, setEndDate] = useState(Moment(new Date()).format("yyyy-MM-DD")+' 23:59:59')
  const[store, setStore] = useState([]);
  const[link, setLink] = useState('');
  const[newTab, setNewTab] = useState(false);
  const[cookies] = useCookies(ACCESS_TOKEN)
  const[error, setError] = useState('');
  const[success, setSuccess] = useState('');
  const [csrfTokenState, setCsrfTokenState] = useState(''); 

  let navigate = useNavigate()

  const[image, setImage] = useState(null);

  const[selectedStore, updateSelectedStore] = useState(true)

  Moment.locale('en');

  const [espotNames, setEspotNames] = useState([])

  const [data, setData] = useState([]);

  const handleStoreChange = (e) => {
    setStore(Array.isArray(e) ? e.map(x => x.value) : []);
  }


  useEffect(() =>{

    console.log(selectedStore)
    getStores().then(response =>{

      console.log(response);

      let stores = [];
      let storeIds = [];
      if(response.length){

        response.forEach(element => {

          console.log(element.id);
          console.log(element.name);

          let store = {
            value: element.id,
            label: element.name
          }

          stores.push(store);
          storeIds.push(element.id);

        })
      }

      setData(stores);
      setStore(storeIds);

    }).catch(error =>{


    })

  }, [selectedStore])

  useEffect(() =>{

    getEspots().then(response =>{

      console.log(response);

      let espotNames = [];
      if(response.length){

        response.forEach(element => {

          console.log(element.id);
          console.log(element.name);

          let espot = {
            value: element.name,
            label: element.name
          }

          espotNames.push(espot);

        })
      }

      setEspotNames(espotNames);

    }).catch(error =>{


    })

  }, [])

  useEffect(() =>{

    setError('');
    setSuccess('');
    let token = cookies.token;
    getCurrentUser(token).then(response =>{

        if(response.status === 'error'){

            navigate("/login")
    
          }
          if(response.status === 'success'){
    
              setFirstName(response.firstName);
              setLastName(response.lastName);
          }
    
          console.log(response);

    }).catch(error =>{

      console.log(error);
      navigate("/login")

    })

  }, [])


  const onFileChange = (e) =>{

    setImage(e.target.files[0]);

  }

  const handleAdName = e =>{

    setAdName(e.target.value);
  }

  const handleSelectAdName = e =>{

    setSelectAdName(e.target.value);
  }

  const customSelectStyles = {
  
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#191919",
      color: "white",

    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "white"
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: "rgb(9, 113, 241)",
      color: "white",
      borderRadius:"3px"
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      backgroundColor: "rgb(9, 113, 241)",
      color: "white"
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#191919",
      color: "white",
      zIndex:999999999999999,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      backgroundColor: "#191919",
      color: "white"
    }),option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#191919':"#ffffff",
    }),
  };

  const handleSubmit = () =>{

    setError('');
    setSuccess('');
    if(!adName.trim()){

      setError('Enter Ad Name!');

    }else if(selectAdName === -1){

      setError('Enter Choose Ad Spot!');

    }else if(!store.length){

      setError('Please choose store(s)!');

    }else if(!image){

      setError('Please upload image!');

    }else if(!link){

      setError('Please enter link!');

    }else if(!startDate){

      setError('Please choose Start Date!');

    }else if(!endDate){

      setError('Please choose End Date!');

    }else{

      console.log(adName);
      console.log(selectAdName);
      console.log(store)
      console.log(image);
      console.log(link);
      console.log(startDate);
      console.log(Moment(new Date(startDate)).format('yyyy-MM-DD HH:mm')+':00')
      console.log(endDate);
      console.log(Moment(new Date(endDate)).format('yyyy-MM-DD HH:mm')+':59')
      console.log(newTab);
  
 
      const formData = new FormData();
      formData.append("myFile", image);
    
      console.log(image);    

      uploadFile(formData, csrfTokenState).then(response =>{

        console.log(response)
        adCreation();

      }).catch(error =>{

        console.log(error)
      })
    }    
  }

  const adCreation = () =>{

    let finalReq = {};
    let adsInfo = {};
      store.forEach(element => {
        let req = {}
        req.adName = adName;
        req.espotName = selectAdName;
        req.store = element;
        req.image = IMAGE_URL+image.name;
        req.link = link.replace("[dealer]", element);
        req.startDate = Moment(new Date(startDate)).format('yyyy-MM-DD HH:mm')+':00';
        req.endDate = Moment(new Date(endDate)).format('yyyy-MM-DD HH:mm')+':59';
        req.newTab = newTab;
        adsInfo[element] = req;

      });
  
      finalReq.adName = adName;
      finalReq.espotName = selectAdName;
      finalReq.startDate = Moment(new Date(startDate)).format('yyyy-MM-DD HH:mm')+':00';
      finalReq.endDate = Moment(new Date(endDate)).format('yyyy-MM-DD HH:mm')+':59';
      finalReq.json = adsInfo;


      console.log(finalReq);

    
      createJsonAd(Object.assign({}, finalReq), csrfTokenState).then(response => {
  
        if(response){

          if(response.status){

            setSuccess('Ad ('+(adName === ''? selectAdName: adName)+') created successfully!')
            setAdName('')
            setSelectAdName('')
            updateSelectedStore(!selectedStore)
            setLink('')
            setStartDate(Moment(new Date()).format("yyyy-MM-DD")+' 00:00:00')
            setEndDate(Moment(new Date()).format("yyyy-MM-DD")+' 23:59:59')

          }else{

            setError('Something went wrong. Please try again!')
          }
        }
        console.log(response)
  
      }).catch(error => {
  
        console.log(error)
  
      })

  }

  async function getCallToForm() {
      
    getCSRFToken().then(response =>{

      console.log(response)
      setCsrfTokenState(response.csrfToken)

    }).catch(error =>{
      console.log(error)

    })

  }

  useEffect(() =>{

    getCallToForm();
  }, [])

  return (
    
    <div>
        <Header firstName={firstName} lastName={lastName} type='dashboard'/>
        <div className="main-container" id="container">
            <div id="content" className="main-content">
                <Breadcrumb name={"Dashboard"} url={"/"}/>
                <div className="container-fluid login-three-container mt-4">
                    <div className="row main-login-three">
                        <div className="col-xl-8 col-lg-8 col-md-6">
                            <div className="d-flex flex-column justify-content-between right-area widget">
                                <div>
                                <h2 className="text-align-center">Create Ad</h2>
                                    <div className="login-three-inputs mt-5">
                                    <div className='text-align-center' style={{color:'red', fontWeight:'bold'}}>{error}</div><br/>
                                    <div className='text-align-center' style={{color:'green', fontWeight:'bold'}}>{success}</div><br/>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Ad Name: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <input type="text"  placeholder='Ad Name' className='form-control' value={adName} onChange={handleAdName}/> 
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Ad Spot: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <select className="form-control" placeholder='Choose Ad Spot' value={selectAdName} onChange={handleSelectAdName}>
                                              <option id='-1' value='-1'>Choose Ad Spot</option>
                                              {espotNames.map((e, key) => {
                                                  return <option id={key} key={key} value={e.value}>{e.value}</option>;
                                              })}
                                            </select>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Store(s): </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                          <Select
                                              styles={customSelectStyles}
                                              placeholder="Choose Store(s)"
                                              value={data.filter(obj => store.includes(obj.value))} // set selected values
                                              options={data} // set list of the data
                                              onChange={handleStoreChange} // assign onChange function
                                              isMulti
                                              isClearable
                                            />
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Image: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <input className='form-control' type='file' onChange={onFileChange} accept="image/*"/>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Link: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                              <input type="text" placeholder='Please enter Link' className='form-control' value={link} onChange={e => setLink(e.target.value)}/>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Open in new tab: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                              <input type="checkbox" placeholder='Open in new tab' className='form-control newtab-checkbox' value={newTab} onChange={e => setNewTab(e.target.checked)}/>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Start Date: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                              <MobileDateTimePicker
                                                value={startDate}
                                                onChange={(newValue) => {
                                                  setStartDate(newValue);
                                                }}
                                                label="Start Date"
                                                onError={console.log}
                                                minDate={new Date()}
                                                inputFormat="yyyy/MM/dd hh:mm a"
                                                mask="___/__/__ __:__ _M"
                                                renderInput={(params) => <TextField placeholder='___/__/__ __:__ _M' className='form-control' {...params} />}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">End Date: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                              <MobileDateTimePicker
                                                value={endDate}
                                                onChange={(newValue) => {
                                                  setEndDate(newValue);
                                                }}
                                                label="End Date"
                                                onError={(newValue) => {
                                                  if(newValue === 'minDate'){
                                                    setEndDate(Moment(new Date(startDate)).format("yyyy-MM-DD")+' 23:59:59');
                                                  }
                                                }}
                                                minDate={new Date(startDate).setDate(new Date(startDate).getDate())}
                                                inputFormat="yyyy/MM/dd hh:mm a"
                                                mask="___/__/__ __:__ _M"
                                                renderInput={(params) => <TextField className='form-control' placeholder='___/__/__ __:__ _M' {...params} />}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-xl-4 col-lg-12 col-md-12 col-sm-12'></div>
                                        <button className="text-white btn bg-gradient-primary" onClick={handleSubmit}>Submit<i className="las la-key ml-2"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdConfig
