import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../Breadcrumb';
import { ACCESS_TOKEN, BANNER_URL, IMAGE_URL, LOGO_URL } from '../constants';
import Header from '../header';
import { findStoryBy, getCSRFToken, getCurrentUser, getEspots, getStores, updateJsonAd, updateStory, uploadBanner, uploadLogo } from '../util/APIUtil';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import Select from 'react-select';
import Moment from 'moment';


const EditStory = () => {

  let { id } = useParams();

  const[firstName, setFirstName] = useState('');
  const[lastName, setLastName] = useState('');

  const[name, setName] = useState('');
  const[buttonText, setButtonText] = useState('');
  const[startDate, setStartDate] = useState(new Date())
  const[endDate, setEndDate] = useState(null)
  const[store, setStore] = useState([]);
  const[banner, setBanner] = useState(null);
  const[bannerUrl, setBannerUrl] = useState('');
  const[logo, setLogo] = useState(null);
  const[logoUrl, setLogoUrl] = useState('');
  const[items, setItems] = useState('');
  const[cookies] = useCookies(ACCESS_TOKEN)
  const[error, setError] = useState('');
  const[errorStartDate, setErrorStartDate] = useState('');
  const[errorEndDate, setErrorEndDate] = useState('');
  const[success, setSuccess] = useState('');
  const [csrfTokenState, setCsrfTokenState] = useState(''); 


  let navigate = useNavigate()

  

  Moment.locale('en');

  const [espotNames, setEspotNames] = useState([])
  const [data, setData] = useState([]);

  const handleStoreChange = (e) => {
    setStore(Array.isArray(e) ? e.map(x => x.value) : []);
  }


  useEffect(() =>{

    getStores().then(response =>{

      console.log(response);

      let stores = [];
      let storeIds = [];
      if(response.length){

        response.forEach(element => {

          console.log(element.id);
          console.log(element.name);

          let store = {
            value: element.id,
            label: element.name
          }

          stores.push(store);
          storeIds.push(element.id);

        })
      }

      setData(stores);
      setStore(storeIds);

    }).catch(error =>{


    })

  }, [])

  useEffect(() =>{

    getEspots().then(response =>{

      console.log(response);

      let espotNames = [];
      if(response.length){

        response.forEach(element => {

          console.log(element.id);
          console.log(element.name);

          let espot = {
            value: element.name,
            label: element.name
          }

          espotNames.push(espot);

        })
      }

      setEspotNames(espotNames);

    }).catch(error =>{


    })

  }, [])

  useEffect(() =>{

    setError('');
    setSuccess('');
    let token = cookies.token;
    getCurrentUser(token).then(response =>{

        if(response.status === 'error'){

            navigate("/login")
    
          }
          if(response.status === 'success'){
    
              setFirstName(response.firstName);
              setLastName(response.lastName);
              getCallToForm();
              
          }
    
          console.log(response);

    }).catch(error =>{

      console.log(error);
      navigate("/login")

    })

  }, [])

  const loadStoryInfo = () =>{

    if(!id){

      navigate('/dashboard2/adconfig')

    }else{

      let req = {};
      req.id = id;
      findStoryBy(Object.assign({}, req), csrfTokenState).then(response =>{

        response.forEach(element => {
          
          setName(element.name)
          setItems(element.items)
          
          let buttonText = "";
          let logo = "";
          let banner = "";
          let items = "";
          let storeIds = [];
          Object.keys(element.json).forEach(key =>{

            let elem = element.json[key];
            
            storeIds.push(Number(key));
            
            if(logo === ''){

              logo = elem.logo;
            }

            if(banner === ''){

              banner = elem.banner;
            }

            if(buttonText === ''){

               buttonText = elem.buttonText;
            }

          });

          
          setStore(storeIds)
          setBanner(banner)
          setButtonText(buttonText);
          setBannerUrl(banner);
          setLogo(logo);
          setLogoUrl(logo);
          setStartDate(new Date(element.startdate))
          setEndDate(new Date(element.enddate))
          

        });
        console.log(response)

      }).catch(error =>{

        console.log(error)
      })
    
    }
  }

  const onFileChange = (e) =>{

    setBanner(e.target.files[0]);

  }

  const onLogoChange = (e) =>{

    setLogo(e.target.files[0]);

  }

  const handleName = e =>{

    setName(e.target.value);

  }

  const handleButtonText = e =>{

    setButtonText(e.target.value);

  }

  const customSelectStyles = {
  
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#191919",
      color: "white",

    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "white"
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: "rgb(9, 113, 241)",
      color: "white",
      borderRadius:"3px"
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      backgroundColor: "rgb(9, 113, 241)",
      color: "white"
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#191919",
      color: "white",
      zIndex:999999999999999,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      backgroundColor: "#191919",
      color: "white"
    }),option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#191919':"#ffffff",
    }),
  };

  const handleSubmit = () =>{

    setError('');
    setSuccess('');
    if(!name.trim()){

      setError('Enter Name!');

    }else if(!store.length){

      setError('Please choose store(s)!');

    }else if(!banner){

      setError('Please upload banner!');

    }else if(!logo){

      setError('Please upload logo!');

    }else if(!items){

      setError('Please enter items!');

    }else if(!startDate){

      setError('Please choose Start Date!');

    }else if(!endDate){

      setError('Please choose End Date!');

    }else if(errorStartDate !== '' && errorStartDate !== null){

      window.scrollTo(0, 0);
      console.log("Error Start Date ::: "+errorStartDate);
      setError('Please update Start Date!');

    }else if(errorEndDate !== '' && errorEndDate !== null){
      window.scrollTo(0, 0);
      console.log("Error End Date ::: "+errorEndDate);
      setError('Please update End Date!');
      
    }else{

      console.log(name);
      console.log(buttonText);
      console.log(store)
      console.log(banner);
      console.log(logo);
      console.log(items);
      console.log(startDate);
      console.log(Moment(new Date(startDate)).format('yyyy-MM-DD HH:mm:ss'))
      console.log(endDate);
      console.log(Moment(new Date(endDate)).format('YYYY-MM-DD hh:mm:ss a'))
  
      const formData = new FormData();
      formData.append("myFile", banner);
    
      console.log(banner);   
           

      if(typeof banner !== 'string'){

        uploadBanner(formData, csrfTokenState).then(response =>{

          console.log(response)         
          uploadLOGO();

        }).catch(error =>{
  
          console.log(error)
        })

      }else{

        uploadLOGO();

      }
      
    }    
  }

  const uploadLOGO = () =>{

    if(typeof logo !== 'string'){

      const logoForm = new FormData();
      logoForm.append("myFile", logo);
      uploadLogo(logoForm, csrfTokenState).then(response =>{

        console.log(response);
        storyUpdation();        

      }).catch(error =>{

        console.log(error)
      })

    }else{

      storyUpdation();

    }
  }

  const storyUpdation = () =>{

    let finalReq = {};
    let options = {};
    let storyInfo = {};
      store.forEach(element => {
        let req = {}
        req.name = name;
        req.store = element;
        if(logo instanceof File){

          req.logo = LOGO_URL+logo.name;
        }else{
          req.logo = logo;
        }

        if(banner instanceof File){

          req.banner = BANNER_URL+banner.name;
        }else{
          req.banner = banner;
        }
        
        req.buttonText = buttonText;
        req.startDate = Moment(new Date(startDate)).format('yyyy-MM-DD HH:mm')+':00';
        req.endDate = Moment(new Date(endDate)).format('yyyy-MM-DD HH:mm')+':59';
        storyInfo[element] = req;

      });
  
      options.name = name;
      options.items = items;
      options.startDate = Moment(new Date(startDate)).format('yyyy-MM-DD HH:mm')+':00';
      options.endDate = Moment(new Date(endDate)).format('yyyy-MM-DD HH:mm')+':59';
      options.json = storyInfo;

      finalReq.options = options;
      finalReq.id = id;

      console.log(finalReq);

      updateStory(Object.assign({}, finalReq), csrfTokenState).then(response => {
  
        if(response){

          if(response.status){

            setSuccess('Story Name ('+name+') updated successfully!')
            
          }else{

            setError('Something went wrong. Please try again!')
          }
        }
        console.log(response)
  
      }).catch(error => {
  
        console.log(error)
  
      })
  }

  async function getCallToForm() {
      
    getCSRFToken().then(response =>{

      console.log(response)
      setCsrfTokenState(response.csrfToken)

    }).catch(error =>{
      console.log(error)

    })

  }

  useEffect(() =>{

    if(csrfTokenState){
      loadStoryInfo();
    }

  }, [csrfTokenState])

  return (
    
    <div>
        <Header firstName={firstName} lastName={lastName} type='stories'/>
        <div className="main-container" id="container">
            <div id="content" className="main-content">
              <Breadcrumb name={"Vendor Stories"} url={"/stories"}/>
                <div className="container-fluid login-three-container mt-4">
                    <div className="row main-login-three">
                        <div className="col-xl-8 col-lg-8 col-md-6">
                            <div className="d-flex flex-column justify-content-between right-area widget">
                                <div>
                                <h2 className="text-align-center">Edit Story</h2>
                                    <div className="login-three-inputs mt-5">
                                    <div className='text-align-center' style={{color:'red', fontWeight:'bold'}}>{error}</div><br/>
                                    <div className='text-align-center' style={{color:'green', fontWeight:'bold'}}>{success}</div><br/>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Story Name: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <input type="text"  placeholder='Story Name' className='form-control' value={name} onChange={handleName} readOnly/> 
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Store(s): </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                          <Select
                                              styles={customSelectStyles}
                                              placeholder="Choose Store(s)"
                                              value={data.filter(obj => store.includes(obj.value))} // set selected values
                                              options={data} // set list of the data
                                              onChange={handleStoreChange} // assign onChange function
                                              isMulti
                                              isClearable
                                              isDisabled={false}
                                            />
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Logo: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <input className='form-control' type='file' onChange={onLogoChange} accept="image/*"/>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Current Logo: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <img src={logoUrl} style={{width:"100%"}}/>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Banner: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <input className='form-control' type='file' onChange={onFileChange} accept="image/*"/>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Current Banner: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <img src={bannerUrl} style={{width:"100%"}}/>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Items: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                              <input type="text" placeholder='Please enter Items (comma seperated)' className='form-control' value={items} onChange={e => setItems(e.target.value)}/>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Button Text: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <input type="text"  placeholder='Button Text' className='form-control' value={buttonText} onChange={handleButtonText}/> 
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">Start Date: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                              <MobileDateTimePicker
                                                value={startDate}
                                                onChange={(newValue) => {
                                                  setStartDate(newValue);
                                                }}
                                                label="Start Date"
                                                onError={(reason, value)=>{setErrorStartDate(reason)}}
                                                inputFormat="yyyy/MM/dd hh:mm a"
                                                mask="___/__/__ __:__ _M"
                                                renderInput={(params) => <TextField placeholder='___/__/__ __:__ _M' className='form-control' {...params} />}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-form-label text-right col-lg-3 col-sm-12">End Date: </label>
                                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                              <MobileDateTimePicker
                                                value={endDate}
                                                onChange={(newValue) => {
                                                  setEndDate(newValue);
                                                }}
                                                label="End Date"
                                                onError={(reason, value)=>{setErrorStartDate(reason)}}
                                                minDate={new Date(startDate) > new Date()? new Date(startDate).setDate(new Date(startDate).getDate() + 1) : new Date().setDate(new Date().getDate()+1)}
                                                inputFormat="yyyy/MM/dd hh:mm a"
                                                mask="___/__/__ __:__ _M"
                                                renderInput={(params) => <TextField className='form-control' placeholder='___/__/__ __:__ _M' {...params} />}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-xl-4 col-lg-12 col-md-12 col-sm-12'></div>
                                        <button className="text-white btn bg-gradient-primary" onClick={handleSubmit}>Submit<i className="las la-key ml-2"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditStory
