
import "../assets/bootstrap/css/bootstrap.min.css";
import "../assets/custom/css/main.css";
import "../assets/custom/css/structure.css";
import "../index.css";
import "../assets/custom/css/loader.css";
import "../assets/custom/css/authentication/auth_3.css";
import "../assets/custom/css/darkmode/global.css";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../Dashboard";
import Login from "../login"
import AdConfig from "../AdConfig";
import EditAdConfig from "../EditAdConfig";
import Dashboard2 from "../Dashboard-v2/Dashboard"
import AdConfig2 from "../Dashboard-v2/AdConfig";
import EditAdConfig2 from "../Dashboard-v2/EditAdConfig";
import Stories from "../Vendor-Stories/Stories"
import AddStory from "../Vendor-Stories/AddStory";
import EditStory from "../Vendor-Stories/EditStory";


const App = () =>{

  return (
    
  <div>
    <Routes>      
      {/* <Route exact path="/" element={<Dashboard2 />}/> */}
      <Route exact path="/login" element={<Login />}/>
      {/* <Route exact path="/adconfig" element={<AdConfig />}/>
      <Route exact path="/adconfig/:id" element={<EditAdConfig />}/> */}

      {/* Dashboard version 2.0 */}
      <Route exact path="/" element={<Dashboard2 />}/>
      <Route exact path="/adconfig" element={<AdConfig2 />}/>
      <Route exact path="/adconfig/:id" element={<EditAdConfig2 />}/>

      {/* Vendor Stories */}
      <Route exact path="/stories" element={<Stories />}/>
      <Route exact path="/addstory" element={<AddStory />}/>
      <Route exact path="/addstory/:id" element={<EditStory />}/>

    </Routes>     
  </div>
)
 
}

document.body.classList.add("darkmode")
export default App;
