import { ADS_FILTERS, API_BASE_URL, CREATEAD, CREATEJSONAD, CREATESTORY, DELETE_AD, DELETE_ADSFILTERED_ITEMS, DELETE_FILTERED_ITEMS, DELETE_FILTERED_STORIES, DELETE_JSON_AD, DELETE_STORY, DISTINCT_ADNAMES, ESPOTS_URL, FILTERS, FINDADBY, FINDADCONTENTBY, FINDADSBY, FINDALLADS, FINDALL_ADCONTENT, FINDALL_ADS, FINDSTORIESBY, FINDSTORYBY, FIND_ADCONTENTVALUE_BY, STORES_URL, STORY_NAMES, TOKEN_URL, UPDATE_AD, UPDATE_JSON_ADD, UPDATE_STORY, UPLOAD_BANNER, UPLOAD_IMAGE, UPLOAD_LOGO } from '../constants';

const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if(options.token !== 'undefined' && options.token !== null && options.token !== undefined && options.token !== 'null') {
        headers.append('Authorization', 'Bearer ' + options.token)
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
};

const csrfRequest = (options) => {
    const headers = new Headers();

    if(options.token !== 'undefined' && options.token !== null && options.token !== undefined && options.token !== 'null') {
        headers.append('xsrf-token', options.token)
    }

    if(options.formData){
        
        
    }else{

        headers.append("Content-Type", "application/json");

    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export function login(loginRequest, token) {
    return request({
        url: API_BASE_URL + "/login",
        method: 'POST',
        body: JSON.stringify(loginRequest),
        token: null
    });
}

export function getCurrentUser(token) {
    if(!token) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: API_BASE_URL + "/userinfo",
        method: 'GET',
        token: token
    });
}

export function getAllAds(){

    return request({

        url: FINDALL_ADS,
        method: 'GET',
    })
}

export function findAllAdContent(){

    return request({

        url: FINDALL_ADCONTENT,
        method: 'GET',
    })
}

export function findAllAds(){

    return request({

        url: FINDALLADS,
        method: 'GET',
    })
}

export function distinctAdNames(){

    return request({

        url: DISTINCT_ADNAMES,
        method: 'GET',
    })
}

export function findAdContent(req){

    return request({

        url: FINDADCONTENTBY,
        method: 'POST',
        body: JSON.stringify(req)
    })
}

export function createAd(req, token){

    return csrfRequest({

        url: CREATEAD,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function findAdContentBy(req, token){

    return csrfRequest({
        url: FINDADCONTENTBY,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function findAdContentValueBy(req, token){

    return csrfRequest({
        url: FIND_ADCONTENTVALUE_BY,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function updateAd(req, token){

    return csrfRequest({
        url: UPDATE_AD,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function deleteAd(req, token){

    return csrfRequest({
        url: DELETE_AD,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function deleteFilteredItems(req, token){

    return csrfRequest({
        url: DELETE_FILTERED_ITEMS,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function uploadFile(req, token){

    return csrfRequest({

        url: UPLOAD_IMAGE,
        method:'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        formData: true,
        body:req
    })
}

export const getCSRFToken = (req, token) =>{

    return csrfRequest({

        url: TOKEN_URL,
        method:'GET',
        credentials: "include",
        mode: 'cors',

    })

}

export function getFilters(){

    return request({

        url: FILTERS,
        method: 'GET',
    })
}

export function getStores(){

    return request({

        url: STORES_URL,
        method: 'GET',
    })
}

export function getEspots(){

    return request({

        url: ESPOTS_URL,
        method: 'GET',
    })
}

// Dashboard version 2.0

export function createJsonAd(req, token){

    return csrfRequest({

        url: CREATEJSONAD,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function findAdsBy(req, token){

    return csrfRequest({
        url: FINDADSBY,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function findAdBy(req, token){

    return csrfRequest({
        url: FINDADBY,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function updateJsonAd(req, token){

    return csrfRequest({
        url: UPDATE_JSON_ADD,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function deleteJsonAd(req, token){

    return csrfRequest({
        url: DELETE_JSON_AD,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function getAdsFilters(){

    return request({

        url: ADS_FILTERS,
        method: 'GET',
    })
}

export function deleteAdsFilteredItems(req, token){

    return csrfRequest({
        url: DELETE_ADSFILTERED_ITEMS,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

// Vendor Stories

export function createStory(req, token){

    return csrfRequest({

        url: CREATESTORY,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function findStoriesBy(req, token){

    return csrfRequest({
        url: FINDSTORIESBY,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function uploadBanner(req, token){

    return csrfRequest({

        url: UPLOAD_BANNER,
        method:'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        formData: true,
        body:req
    })
}

export function uploadLogo(req, token){

    return csrfRequest({

        url: UPLOAD_LOGO,
        method:'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        formData: true,
        body:req
    })
}

export function findStoryBy(req, token){

    return csrfRequest({
        url: FINDSTORYBY,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function updateStory(req, token){

    return csrfRequest({
        url: UPDATE_STORY,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function deleteStory(req, token){

    return csrfRequest({
        url: DELETE_STORY,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function deleteFilteredStories(req, token){

    return csrfRequest({
        url: DELETE_FILTERED_STORIES,
        method: 'POST',
        credentials: "include",
        token: token,
        mode: 'cors',
        body: JSON.stringify(req),
    })
}

export function getStoryNames(){

    return request({

        url: STORY_NAMES,
        method: 'GET',
    })
}