import React, { useEffect, useState } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import { getCSRFToken, getCurrentUser, getStores, findStoriesBy, deleteStory, deleteFilteredStories, getStoryNames } from '../util/APIUtil'
import Breadcrumb from '../Breadcrumb';
import Header from '../header';
import { useCookies } from 'react-cookie';
import { ACCESS_TOKEN } from '../constants';
import { useNavigate } from 'react-router-dom';
import Moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { TablePagination } from '@mui/material';
import DatatableLoader from '../DatatableLoader';
import { ArrowDownward } from '@material-ui/icons';

import { DateRangePicker } from 'rsuite'
import {addDays} from 'date-fns'

//Sweet alert
import swal from 'sweetalert'

import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const Stories = () =>{

  
  const[data, setData] = useState([])
  const[firstName, setFirstName] = useState('')
  const[lastName, setLastName] = useState('')
  const[cookies] = useCookies(ACCESS_TOKEN);
  const[store, setStore] = useState(0); 
  const[id, setId] = useState(0);
  const[csrfTokenState, setCsrfTokenState] = useState(''); 
  const[names, setNames] = useState([]);
  const[name, setName] = useState('')
  const[queryFields, setQueryFields] = useState({})

  //Pagination
  const[page, setPage] = useState(0);
  const[totalRows, setTotalRows] = useState(0);
  const[rowsPerPage, setRowsPerPage] = useState(10);

  //Progress
  const [progress, setProgress] = useState(false)

  //sort icon
  const sortIcon = <ArrowDownward />;

  //sort
  const[sortOrder, setSortOrder] = useState('asc');
  const[sortColumn, setSortColumn] = useState('name');

  //Filter startdate and enddate
  const[startDate, setStartDate] = useState(null)
  const[endDate, setEndDate] = useState(null)

  const[fromDate, setFromDate] = useState(null)
  const[toDate, setToDate] = useState(null)

  const[dateRangeValue, setDateRangeValue] = useState([])



  const [stores, setStores] = useState([]);

  const[ids, setIds] = useState({
    id: 0,
    banner:'',
    logo:''
  })

  const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  const[columns, setColumns] = useState([
    {
      id: 'store',
      name: 'Store',
      selector: row => row['store'],
      sortable: true,
      width: "10%",
      cell: row => <div className="vendor-stories-text"><Tooltip arrow={true} title={row.store}><div>{row['store']}</div></Tooltip></div>
    },
    {
      id: 'name',
      name: 'name',
      selector: row => row['name'],
      sortable: true,
      width: "10%"
    },
    {
      id: 'items',
      name: 'items',
      selector: row => row['items'],
      sortable: true,
      width: "15%",
      cell: row => <div className="vendor-stories-items"><Tooltip arrow={true} title={row.items}><div>{row['items']}</div></Tooltip></div>
    },
    {
      id: 'logo',
      name: 'logo',
      selector: row => <img src={row['logo']} style={{width:"100%"}}/>,
      width: "15%"
    },
    {
      id: 'banner',
      name: 'banner',
      selector: row => <NoMaxWidthTooltip arrow={true} title={<img src={row['banner']}/>}><img src={row['banner']} style={{width:"100%"}}/></NoMaxWidthTooltip>,
      width: "15%"
    },
    {
      id: 'startdate',
      name: 'Start Date',
      selector: row => row['startDate'],
      sortable: true,
      //width: "5%"
    },
    {
      id: 'enddate',
      name: 'End Date',
      selector: row => row['endDate'],
      sortable: true,
      //width: "5%"
    },
    {
      id: 'Action',
      name: 'Action',
      cell: row => <><button className='btn btn-primary pl-4 pr-4 mr-2' id={row.id} onClick={() => setId(row.id)}>Edit</button><button className='btn btn-danger' onClick={() => setIds({'id': row.id, 'banner': row.banner, 'logo': row.logo})}>Delete</button></>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "10%"

    }])

    useEffect(() =>{

      getStores().then(response =>{
  
        console.log(response);
  
        let data = [];
        if(response.length){
  
          response.forEach(element => {
  
            console.log(element.id);
            console.log(element.name);
  
            let store = {
              value: element.id,
              label: element.name
            }
  
            data.push(store);
  
          })
        }
  
        setStores(data);
  
      }).catch(error =>{
  
  
      })
  
    }, [])

    useEffect(() =>{

      if(ids.id !==0){

        swal({
          title: "Warning!",
          text: "Are you sure want to delete!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
    
            deleteStory(Object.assign({}, ids), csrfTokenState).then(response =>{

              console.log(response)
              if(response){
    
                if(response.status){
    
                  swal("Deleted successfully!", {
                    icon: "success",
                  }).then((value) => {
                    allStories();
                  });
                }
              }
    
            }).catch(error => {
    
              swal("Something went wrong, Please try again later!", {
                icon: "error",
              });
              console.log(error)
            });
    
          }
        });
        console.log(ids);
        
      }

    },[ids])
    useEffect(() =>{

      if(id !== 0){

          navigate('/addstory/'+id);
      }

    }, [id])

    async function getCallToForm() {
      
      getCSRFToken().then(response =>{
  
        console.log(response)
        setCsrfTokenState(response.csrfToken)
  
      }).catch(error =>{
        console.log(error)
  
      })
  
    }
  
    useEffect(() =>{
  
      getCallToForm();
    }, [])

  
  useEffect(() =>{

      filters();

  }, []);

  const filters =  () =>{

    getStoryNames().then( response =>{

      console.log(response);

      if(response.length){

        let storyNames = [];
        response.forEach(element => {
          
          if(element.length){

            element.forEach(elem => {

              if(elem['name']){

                storyNames.push(elem['name']);

              }

            });

          }

        });

        setNames(storyNames);

      }

    }).catch(error =>{

    })

  }



  let navigate = useNavigate();

  useEffect(() =>{

    let token = cookies.token;
    getCurrentUser(token).then(response => {

      if(response.status === 'error'){

        navigate("/login")

      }
      if(response.status === 'success'){

          setFirstName(response.firstName);
          setLastName(response.lastName);
      }

      console.log(response);

    }).catch(error =>{

      console.log(error);
      navigate("/login")

    })
  }, [])

  useEffect(() =>{

    if(csrfTokenState){

      allStories();

    }

  }, [csrfTokenState, sortColumn, sortOrder, page, rowsPerPage, queryFields])

  const handleClearFilter = () =>{
    
    setStore(0)
    setName('')
    setFromDate(null)
    setToDate(null)
    let query = {}
    setPage(0)
    setSortColumn('name')
    setSortOrder('asc')
    setDateRangeValue([])
    setQueryFields(query);

  }

  const handleApplyFilter = () =>{

    let query = {};
    if(store){

      query.store = store;
    }

    if(name){

      query.name = name;

    }

    if(fromDate && toDate){

      query.fromDate = fromDate;
      query.toDate = toDate;
    }

    setPage(0);
    setQueryFields(query);
  }

  const allStories = () => {

    setProgress(true)

    let req = {}
    let pagination = {};
    pagination.limit = rowsPerPage;
    pagination.offset = rowsPerPage * page;

    let order = [];
    if(sortColumn && sortOrder){
      order.push(sortColumn);
      order.push(sortOrder.toUpperCase());
      req.sortColumn = sortColumn;
    }
    req.order = order;
    
    req.where = queryFields;
    req.pagination = pagination;

    getStores().then(response =>{
  
      console.log(response);

      let dealers = {};
      if(response.length){

        response.forEach(element => {

          dealers[element.id] = element.name;

        })
      }

      findStoriesBy(Object.assign({}, req), csrfTokenState).then(response =>{

        let vendorStories = [];
        let rowsCount = 0;
        if(response.totalRows){
  
          rowsCount = response.totalRows;
        }
  
        setTotalRows(rowsCount);
  
        if(response.data){
  
          const data = response.data;
          if(data.length){
            data.forEach(element => {
            
              let story = {};
              story.name = element.name;
              story.id = element.id;
              story.items = element.items;
              const json = element.json;
  
              let allStores = [];
              let allBanners = [];
              let allLogos = [];
              Object.keys(json).forEach(key => {
  
                const element = json[key];
  
                const dealerName = dealers[Number(key)];
  
                allStores.push(((dealerName)?dealerName:key));
                allBanners.push(element.banner);
                allLogos.push(element.logo);
  
              });
  
              story.store = allStores.join(",");
              story.logo = allLogos[0];
              story.banner = allBanners[0];
              
              const startDate = Moment(element.startdate).format("yyyy-MM-DD HH:mm:ss")
              const endDate = Moment(element.enddate).format("yyyy-MM-DD HH:mm:ss")
              story.startDate = startDate;
              story.endDate = endDate;
              vendorStories.push(story);
            
            });
          }
  
        }
  
        console.log(vendorStories)
        setData(vendorStories)
        console.log(response)
        setProgress(false);
  
      }).catch(error =>{
  
        console.log(error);
        setProgress(false);
  
      })


    }).catch(error =>{


    })

  }

  const deleteItems = () => {

    let req = {}
    let pagination = {};
    pagination.limit = rowsPerPage;
    pagination.offset = rowsPerPage * page;

    let order = [];
    if(sortColumn && sortOrder){
      order.push(sortColumn);
      order.push(sortOrder.toUpperCase());
      req.sortColumn = sortColumn;
    }
    req.order = order;
    
    req.where = queryFields;
    req.pagination = pagination;

    deleteFilteredStories(Object.assign({}, req), csrfTokenState).then(response =>{

      console.log(response)

      if(response.status){

          if(response.status === 'success'){

            swal("Deleted successfully!", {
              icon: "success",
            });

            handleClearFilter();
            filters();

          }else if(response.status === 'error'){
            swal("Something went wrong, Please try again later!", {
              icon: "error",
            });
          }else{

            swal("Something went wrong, Please try again later!", {
              icon: "error",
            });
          }
      }else{

        swal("Something went wrong, Please try again later!", {
          icon: "error",
        });
      }

    }).catch(error =>{

      console.log(error);
      swal("Something went wrong, Please try again later!", {
        icon: "error",
      });
    })

  }

  createTheme('darktheme', {
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
    },
    background: {
      default: '#272727',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#000000',
    },
    striped: {
      default: "#191919",
      text: "#ffffff"
    },button: {
      default: "#ffffff",
      focus: "#404040",
      hover: "#4d4d4d",
      disabled: "gray",
    },highlightOnHover:{

      default:"#080808",
      text:"#ffffff"

    }
  });

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        border: '1px solid #3a3a3a',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        border: '1px solid #3a3a3a',
      },
    }
  };

  //Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  //Pagination styles
  const useStyles = makeStyles((theme) => ({
    selectRoot: {
      color: "black !important",
      backgroundColor: "white"
    },
    select: {
      backgroundColor:"white !important",
      color: "black !important",
      
    },
    menuItem: {
      color: "black !important"
    },
    leftIconButton: {
      "& .MuiButtonBase-root:not([disabled])": {
        color: "white"
      }
    },
    rightIconButton: {
      "& .MuiButtonBase-root:not([disabled])": {
        color: "white"
      }
    },
    actions: {
      "& .MuiButtonBase-root:not([disabled])": {
        color: "white"
      },"& .MuiButtonBase-root:is([disabled])": {
        color: "gray"
      }
    }
  }));
  const classes = useStyles();

  //Sort function
  const handleOnSort = (selectedColumn, sortDirection ) =>{

      console.log(selectedColumn.id, sortDirection)

      setSortColumn(selectedColumn.id);
      setSortOrder(sortDirection);
      
  }

  const handleOkay = (date) =>{
    
    if(date.length){

      console.log(date[0])
      console.log(date[1])
      console.log(Moment(new Date(date[0])).format("yyyy-MM-DD")+' 00:00:00')
      console.log(Moment(new Date(date[1])).format("yyyy-MM-DD")+' 23:59:59')
      setFromDate(Moment(new Date(date[0])).format("yyyy-MM-DD")+' 00:00:00')
      setToDate(Moment(new Date(date[1])).format("yyyy-MM-DD")+' 23:59:59')
      setDateRangeValue(date)

    }

  }

  const handleDeleteFilteredItems = () =>{

    let message = 'Are you sure want to delete all items!';
    if(Object.keys(queryFields).length){

      message = 'Are you sure want to delete filtered items!';

    }
    swal({
      title: "Warning!",
      text: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {

        deleteItems();

      }
    });

  }


  return (
    <>
      <Header firstName={firstName} lastName={lastName} type='stories'/>
      <div className="main-container" id="container">
        <div id="content" className="main-content">
          <Breadcrumb name={"Vendor Stories"} url={"/stories"}/>
          <div className="statbox widget box box-shadow mb-4">
              <div className="widget widget-content widget-content-area">
              <div className="form-group row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="row" data-multiselectsplitter-wrapper-selector="">
                            <div className="col-xs-2 col-sm-2">
                              <label className="col-form-label text-center col-lg-12 col-sm-12">Stores</label>
                              <select className="form-control" data-multiselectsplitter-firstselect-selector="" size="4" onChange={e => setStore(Number(e.target.value))}>
                              {
                              stores.map((e, key) => {
                                    if(store === e.value){

                                      return <option key={key} value={e.value} selected>{e.label}</option>
                                    }
                                    return <option key={key} value={e.value}>{e.label}</option>
                                })}
                              </select>
                            </div>
                            <div className="col-xs-2 col-sm-2">
                              <label className="col-form-label text-center col-lg-12 col-sm-12">Names</label>
                              <select className="form-control" data-multiselectsplitter-firstselect-selector="" size="4" onChange={e => setName(e.target.value)}>
                              {
                              names.map((e, key) => {
                                    if(name === e){

                                      return <option key={key} value={e} selected>{e}</option>
                                    }
                                    return <option key={key} value={e}>{e}</option>
                                })}
                              </select>
                            </div>
                            <div className="col-xs-3 col-sm-3 login-three-inputs">
                              <div className="col-xs-12 col-sm-12 mt-20">
                                <div className="col-xs-10 col-sm-10">
                                  <label className="col-form-label text-center col-lg-12 col-sm-12">Start Date</label>
                                  <DateRangePicker onOk={handleOkay}
                                  value={dateRangeValue}
                                  ranges={[
                                    {
                                      label: 'Today',
                                      value: [new Date(), new Date()]
                                    },
                                    {
                                      label: 'Tomorrow',
                                      value: [addDays(new Date(), 1), addDays(new Date(), 1)]
                                    },
                                    {
                                      label: 'Next 7 days',
                                      value: [new Date(), addDays(new Date(), 6)]
                                    },
                                    {
                                      label: 'Next 30 days',
                                      value: [new Date(), addDays(new Date(), 30)]
                                    }
                                  ]}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-xs-3 col-sm-3">
                              <div className="col-xs-12 col-sm-12 mt-20">
                                <button type="button" className="btn btn-primary" onClick={handleApplyFilter}>Apply Filter</button>
                              </div>
                              <div className="col-xs-12 col-sm-12 mt-2">
                                <button type="button" className="btn btn-danger" onClick={handleClearFilter}>Clear Filter</button>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <button className='btn btn-primary mt-5 mb-2' onClick={() => navigate('/addstory')}>Add New Story</button>
          {data.length?<button className='btn btn-danger mt-5 mb-2 ml-2' onClick={handleDeleteFilteredItems}>Delete Filtered Items</button>:null}
          {progress?null:<TablePagination
            showFirstButton={true}
            showLastButton={true}
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            classes={{
              selectRoot: classes.selectRoot,
              selectIcon: classes.selectRoot,
              input: classes.selectRoot,
              select: classes.select,
              menuItem: classes.menuItem,
              actions: classes.actions
            }}
            backIconButtonProps={{ className: classes.leftIconButton }}
            nextIconButtonProps={{ className: classes.rightIconButton }}
            rowsPerPageOptions={[10, 15, 20, 25]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />}
          <DataTable 
          title='Vendor Stories'
          className="dataTables_wrapper"
          responsive={true}
          pagination = {false}
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={rowsPerPage}
          paginationComponentOptions={{
            noRowsPerPage: true
        }}
          page={page}
          sortServer = {true}
          sortIcon={sortIcon}
          onSort = {handleOnSort}
          progressPending={progress}
          progressComponent = {<DatatableLoader />}
          striped={true}
          columns={columns}
          data={data}
          defaultSortAsc={sortOrder === 'asc'? true: false}
          defaultSortFieldId={sortColumn}
          customStyles={customStyles}
          paginationDefaultPage={1}
          paginationResetDefaultPage = {false}
          theme='darktheme'
          />
          {progress?null:<TablePagination
            showFirstButton={true}
            showLastButton={true}
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            classes={{
              selectRoot: classes.selectRoot,
              selectIcon: classes.selectRoot,
              input: classes.selectRoot,
              select: classes.select,
              menuItem: classes.menuItem,
              actions: classes.actions
            }}
            backIconButtonProps={{ className: classes.leftIconButton }}
            nextIconButtonProps={{ className: classes.rightIconButton }}
            rowsPerPageOptions={[10, 15, 20, 25]}
            onRowsPerPageChange={handleChangeRowsPerPage}

          />}
        </div>
      </div>
    </>
  );
}

export default Stories