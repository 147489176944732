export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://gam.venxia.com/api';
export const FINDALL_ADS = process.env.REACT_APP_FINDALL_ADS || 'https://gam.venxia.com/server/getAds'
export const FINDALL_ADCONTENT = process.env.REACT_APP_FINDALL_ADCONTENT || 'https://gam.venxia.com/server/findAllAdContent'
export const FINDADCONTENTBY = process.env.REACT_APP_FINDADCONTENTBY || 'https://gam.venxia.com/server/findAdContentBy'
export const FINDALLADS = process.env.REACT_APP_FINDALLADS || 'https://gam.venxia.com/server/findAllAds'
export const DISTINCT_ADNAMES = process.env.REACT_APP_DISTINCT_ADNAMES || 'https://gam.venxia.com/server/distinctAdNames'
export const CREATEAD = process.env.REACT_APP_CREATEAD || 'https://gam.venxia.com/server/createAd'
export const FIND_ADCONTENTVALUE_BY = process.env.REACT_APP_FIND_ADCONTENTVALUE_BY || 'https://gam.venxia.com/server/findContentValueBy'
export const DELETE_AD = process.env.REACT_APP_DELETE_AD || 'https://gam.venxia.com/server/deleteAd'
export const UPDATE_AD = process.env.REACT_APP_DELETE_AD || 'https://gam.venxia.com/server/updateAd'

export const UPLOAD_IMAGE = process.env.REACT_APP_UPLOAD_IMAGE || 'https://gam.venxia.com/server/uploadImage'

export const TOKEN_URL = process.env.REACT_APP_TOKEN_URL || 'https://gam.venxia.com/server/form'

export const FILTERS = process.env.REACT_APP_FILTERS || 'https://gam.venxia.com/server/filters'

export const DELETE_FILTERED_ITEMS = process.env.REACT_APP_DELETE_FILTERED_ITEMS || 'https://gam.venxia.com/server/deletefiltered'


export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL || 'https://content.venxia.com/gam/images/'

export const STORES_URL = process.env.REACT_APP_IMAGE_URL || 'https://gam.venxia.com/server/stores'

export const ESPOTS_URL = process.env.REACT_APP_IMAGE_URL || 'https://gam.venxia.com/server/espots'


export const ACCESS_TOKEN = 'token';


// Dashboard version 2.0

export const CREATEJSONAD = process.env.REACT_APP_CREATEJSONAD || 'https://gam.venxia.com/server/createJsonAd'

export const FINDADSBY = process.env.REACT_APP_FINDADSBY || 'https://gam.venxia.com/server/findAdsBy'

export const FINDADBY = process.env.REACT_APP_FINDADBY || 'https://gam.venxia.com/server/findAdBy'

export const UPDATE_JSON_ADD = process.env.REACT_UPDATE_JSON_ADD || 'https://gam.venxia.com/server/updateJsonAd'

export const DELETE_JSON_AD = process.env.REACT_APP_DELETE_JSON_AD || 'https://gam.venxia.com/server/deleteJsonAd'

export const ADS_FILTERS = process.env.REACT_APP_ADS_FILTERS || 'https://gam.venxia.com/server/adsfilters'

export const DELETE_ADSFILTERED_ITEMS = process.env.REACT_APP_DELETE_ADSFILTERED_ITEMS || 'https://gam.venxia.com/server/deleteadsfiltered'

// Vendor Stories

export const CREATESTORY = process.env.REACT_APP_CREATESTORY || 'https://gam.venxia.com/server/createStory'

export const FINDSTORIESBY = process.env.REACT_APP_FINDSTORIESBY || 'https://gam.venxia.com/server/findStoriesBy'

export const UPDATE_STORY = process.env.REACT_UPDATE_UPDATE_STORY || 'https://gam.venxia.com/server/updateStory'

export const DELETE_STORY = process.env.REACT_UPDATE_DELETE_STORY || 'https://gam.venxia.com/server/deleteStory'

export const DELETE_FILTERED_STORIES = process.env.REACT_APP_DELETE_FILTERED_STORIES || 'https://gam.venxia.com/server/deletefilteredstories'

export const UPLOAD_BANNER = process.env.REACT_APP_UPLOAD_BANNER || 'https://gam.venxia.com/server/uploadBanner'

export const UPLOAD_LOGO = process.env.REACT_APP_UPLOAD_LOGO || 'https://gam.venxia.com/server/uploadLogo'

export const FINDSTORYBY = process.env.REACT_APP_FINDSTORYBY || 'https://gam.venxia.com/server/findStoryBy'

export const BANNER_URL = process.env.REACT_APP_BANNER_URL || 'https://content.venxia.com/gam/images/stories/banner/'

export const LOGO_URL = process.env.REACT_APP_LOGO_URL || 'https://content.venxia.com/gam/images/stories/logo/'

export const STORY_NAMES = process.env.REACT_APP_STORY_NAMES || 'https://gam.venxia.com/server/storynames'

